.header-tab-vinculacion {
    li.nav-item {
        border-bottom: 1px solid #f57d2f;
        font-size: 1.2em;
        a.nav-link {
            padding: 15px;
            cursor: pointer;
        }

        a.active {
            background-color: #f57d2f;
            color: #ffffff;
        }
    }
}

.contenido-tabs {
    margin-top: 50px;
    margin-bottom: 70px;
    input {
        border: 1px solid #f57d2f;
    }
    select {
        border: 1px solid #f57d2f;
    }
}
