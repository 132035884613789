/**=====================
    60. RTL CSS Start
==========================**/
[main-theme-layout='rtl'] {
    .location-checkbox {
        span {
            padding-right: 33px;
            padding-left: unset;
        }
    }
    .color-box {
        button {
            + button {
                margin-left: unset;
                margin-right: 8px;
            }
        }
    }
    .navs-dropdown {
        .onhover-show-div {
            right: 0;
        }
    }
    .default-according {
        &.style-1 {
            button {
                text-align: right;
                &:before {
                    left: 20px;
                    right: unset;
                }
                i {
                    right: 18px;
                    left: unset;
                }
            }
        }
        .card {
            .card-header {
                i {
                    right: 18px;
                    left: unset;
                }
            }
        }
    }
    .ProfileCard-details {
        padding-right: 30px;
        padding-left: unset;
        // float: right;
    }
    .starter-main {
        .card-body {
            ul {
                padding-right: 30px;
                padding-left: unset;
            }
        }
    }
    .theme-tab {
        .tab-title {
            li {
                a {
                    svg {
                        margin-left: 5px;
                        margin-right: unset;
                    }
                }
            }
        }
    }
    .spent {
        .spent-graph {
            .project-budget {
                margin-left: 30px;
                margin-right: unset;
            }
        }
    }
    .support-table {
        table {
            tbody {
                tr {
                    td {
                        &:first-child {
                            padding-right: 0;
                            padding-left: unset;
                        }
                    }
                }
            }
        }
    }
    .bookmark {
        ul {
            li {
                .search-form {
                    .form-control-search {
                        left: -50px;
                        right: unset;
                        &:before {
                            right: 50px;
                            left: unset;
                        }
                        &:after {
                            right: 20px;
                            left: unset;
                        }
                    }
                }
            }
        }
    }
    .widget-joins {
        .row {
            .pr-0 {
                padding-right: 15px !important;
            }
            .pl-0 {
                padding-left: 15px !important;
            }
        }
    }
    .order-box {
        .sub-total,
        .total,
        .qty {
            .shipping-class,
            li {
                .shopping-checkout-option,
                span {
                    float: left;
                }
            }
            li {
                .count {
                    float: left;
                }
            }
        }
    }
    .img-paypal {
        margin-right: 15px;
        margin-left: unset;
    }
    .cart {
        .qty-box {
            .input-group {
                .btn {
                    border-radius: 0 !important;
                }
            }
        }
    }
    .todo {
        .action-box {
            text-align: left;
        }
    }
    .product-qnty {
        fieldset {
            .input-group {
                .input-group-prepend {
                    .btn {
                        border-top-right-radius: 0;
                        border-bottom-right-radius: 0;
                    }
                }
            }
        }
    }
    .grid-options {
        margin-right: 10px;
        margin-left: unset;
        ul {
            li {
                a {
                    .line-grid-1 {
                        right: 12px;
                        left: unset;
                    }
                    .line-grid-2 {
                        right: 18px;
                        left: unset;
                    }
                    .line-grid-3 {
                        right: 36px;
                        left: unset;
                    }
                    .line-grid-4 {
                        right: 42px;
                        left: unset;
                    }
                    .line-grid-5 {
                        right: 48px;
                        left: unset;
                    }
                    .line-grid-6 {
                        right: 66px;
                        left: unset;
                    }
                    .line-grid-7 {
                        right: 72px;
                        left: unset;
                    }
                    .line-grid-8 {
                        right: 78px;
                        left: unset;
                    }
                    .line-grid-9 {
                        right: 84px;
                        left: unset;
                    }
                    .line-grid-10 {
                        right: 103px;
                        left: unset;
                    }
                    .line-grid-11 {
                        right: 109px;
                        left: unset;
                    }
                    .line-grid-12 {
                        right: 115px;
                        left: unset;
                    }
                    .line-grid-13 {
                        right: 121px;
                        left: unset;
                    }
                    .line-grid-14 {
                        right: 127px;
                        left: unset;
                    }
                    .line-grid-15 {
                        right: 133px;
                        left: unset;
                    }
                }
            }
        }
    }
    .product-wrapper {
        &.sidebaron {
            .product-grid {
                .product-wrapper-grid {
                    margin-right: calc(25% + 9px);
                    margin-left: unset;
                }
            }
        }
    }
    .product-filter {
        &.new-products {
            .owl-theme {
                .owl-nav {
                    left: 0;
                    right: unset;
                }
                .owl-item {
                    .item {
                        .product-box {
                            .product-details {
                                &.text-left {
                                    text-align: left !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .product-sidebar {
        .filter-section {
            .card {
                .card-header {
                    h6 {
                        .pull-right {
                            i {
                                left: auto;
                                right: unset;
                            }
                        }
                    }
                }
            }
        }
    }
    .feature-products {
        form {
            .form-group {
                i {
                    left: 30px;
                    right: unset;
                }
            }
        }
    }
    .product-wrapper-grid {
        &.list-view {
            .product-box {
                .product-details {
                    text-align: right;
                }
            }
        }
    }
    .page-wrapper {
        .page-body-wrapper {
            .page-header {
                .breadcrumb {
                    margin-right: 30px;
                    margin-left: unset;
                }
            }
        }
        &.horizontal_sidebar {
            .page-body-wrapper {
                .page-sidebar {
                    ~ .page-body {
                        margin-right: 0;
                    }
                    .sidebar-menu {
                        > li {
                            padding: 20px 0 20px 40px;
                            > a {
                                i {
                                    &.fa.pull-right {
                                        margin-left: 0;
                                        margin-right: 10px;
                                    }
                                }
                                &:hover {
                                    padding-right: 0;
                                }
                            }
                            > .sidebar-submenu {
                                &:after {
                                    left: unset;
                                    right: 8px;
                                }
                            }
                            &:hover {
                                .sidebar-submenu {
                                    &.horizontal-child-sub {
                                        margin-right: 86%;
                                        margin-top: 0;
                                    }
                                }
                            }
                        }
                        li {
                            .sidebar-submenu {
                                margin-right: 0;
                            }
                        }
                    }
                    .main-header-left {
                        right: 0;
                        left: unset;
                    }
                }
            }
            .page-main-header {
                margin-right: 0;
            }
        }
    }
    .bookmark {
        ul {
            li {
                &:first-child {
                    padding-right: 0;
                    padding-left: 5px;
                }
                &:last-child {
                    padding-left: 0;
                    padding-right: 5px;
                }
            }
        }
    }
    .mr-0,
    .mx-0 {
        margin-left: 0 !important;
        margin-right: unset !important;
    }
    .mr-1,
    .mx-1 {
        margin-left: 0.25rem !important;
        margin-right: unset !important;
    }
    .pull-left {
        float: right;
    }
    .footer {
        .pull-right {
            float: none;
        }
    }
    .page-wrapper {
        .page-body-wrapper {
            footer {
                margin-right: 255px;
                margin-left: 0px;
            }
            .page-sidebar {
                &.open {
                    ~ footer {
                        margin-right: 0px;
                    }
                }
            }
        }
        &.compact-wrapper {
            .page-body-wrapper {
                &.sidebar-icon {
                    .footer {
                        margin-left: 0;
                    }
                }
            }
        }
        &.compact-page {
            .page-body-wrapper {
                footer {
                    margin-right: 75px;
                }
            }
        }
    }
    .checkbox_animated,
    .radio_animated {
        margin: 0 8px 0 16px;
    }
    .button.remove {
        left: -10px;
        right: unset;
    }
    .box-layout {
        .page-main-header {
            &.open {
                margin-right: auto;
                padding-right: 0;
            }
        }
    }
    .code-box-copy__btn {
        left: 11px;
        right: unset;
    }
    ul {
        &.nav-menus {
            border-bottom: none;
        }
        &.notification-dropdown {
            &.onhover-show-div {
                li {
                    text-align: right;
                }
            }
        }
    }
    .badge {
        + .badge {
            margin-right: 5px;
            margin-left: unset;
        }
    }
    .offset-xl-3 {
        margin-right: 25%;
        margin-left: unset;
    }
    .m-l-10 {
        margin-right: 10px !important;
        margin-left: unset !important;
    }
    .m-r-20 {
        margin-left: 20px;
        margin-right: unset;
    }
    .m-r-30 {
        margin-left: 30px;
        margin-right: unset;
    }
    .m-r-5 {
        margin-left: 5px;
        margin-right: unset;
    }
    .text-left {
        text-align: right !important;
    }
    .m-r-10 {
        margin-left: 10px;
        margin-right: unset;
    }
    .pr-3,
    .px-3 {
        padding-left: 1rem !important;
        padding-right: unset !important;
    }
    .p-r-0 {
        padding-left: 0;
        padding-right: 15px;
    }
    .m-l-20 {
        margin-right: 20px !important;
        margin-left: unset !important;
    }
    .pl-3,
    .px-3 {
        padding-right: 1rem !important;
    }
    .b-r-light {
        border-left: 1px solid $light-color !important;
        border-right: unset !important;
    }
    .float-right {
        float: left !important;
    }
    .float-left {
        float: right !important;
    }
    .text-right {
        text-align: left !important;
    }
    .border-right {
        border-left: 1px solid #dee2e6 !important;
        border-right: unset !important;
    }
    .pr-0,
    .px-0 {
        padding-left: 0 !important;
        padding-right: unset !important;
    }
    .pl-0,
    .px-0 {
        padding-right: 0 !important;
        padding-left: unset !important;
    }
    .ml-1 {
        margin-left: unset !important;
        margin-right: 0.25rem !important;
    }
    .ml-4,
    .mx-4 {
        margin-right: 1.5rem !important;
        margin-left: unset !important;
    }
    .owl-carousel,
    .vertical-timeline {
        direction: ltr;
    }
    .vertical-timeline {
        .vertical-timeline-element-content {
            .vertical-timeline-element-date {
                text-align: left;
            }
        }
    }
    .btn-group {
        > {
            .btn:not(:last-child):not(.dropdown-toggle),
            :not(:last-child) > .btn {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                border-top-right-radius: 0.25rem;
                border-bottom-right-radius: 0.25rem;
            }
            .btn:not(:first-child) {
                border-top-left-radius: 0.25rem;
                border-bottom-left-radius: 0.25rem;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
            .btn:nth-child(2) {
                border-radius: 0 !important;
            }
        }

        .btn-group > :not(:first-child) > .btn {
            border-top-left-radius: 0.25rem;
            border-bottom-left-radius: 0.25rem;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
        .btn + {
            .btn,
            .btn-group {
                margin-right: -1px;
            }
        }
        .btn-group + {
            .btn,
            .btn-group {
                margin-right: -1px;
            }
        }
        .btn-group-vertical {
            .btn + {
                .btn,
                .btn-group {
                    margin-right: -1px;
                }
            }
            .btn-group + {
                .btn,
                .btn-group {
                    margin-right: -1px;
                }
            }
        }
    }
    .mr-3,
    .mx-3 {
        margin-right: unset !important;
        margin-left: 1rem !important;
    }
    .mr-5,
    .mx-5 {
        margin-left: 3rem !important;
        margin-right: unset !important;
    }
    .ml-3,
    .mx-3 {
        margin-right: 1rem !important;
        margin-left: unset !important;
    }
    .m-r-15 {
        margin-left: 15px;
        margin-right: unset;
    }
    .b-l-light {
        border-right: 1px solid $light-color !important;
        border-left: unset !important;
    }
    .p-l-0 {
        padding-left: unset;
        padding-right: 0px;
    }
    .pl-4 {
        padding-right: 1.5rem !important;
        padding-left: unset !important;
    }
    .mr-2 {
        margin-left: 0.5rem !important;
        margin-right: unset !important;
    }
    .ml-2 {
        margin-right: 0.5rem !important;
        margin-left: unset !important;
    }
    .pr-2 {
        padding-right: unset !important;
        padding-left: 0.5rem !important;
    }
    .alert {
        text-align: right;
    }
    .pull-right {
        float: left;
    }
    .form-inline {
        .form-group {
            margin-left: 15px;
            margin-right: 0;
        }
    }
    .card {
        text-align: right;
        .card-header {
            .card-header-right {
                left: 20px;
                right: unset;
                .card-option {
                    text-align: left;
                    &.list-unstyled {
                        padding-right: 0;
                        padding-left: unset;
                    }
                }
            }
            .pull-left {
                float: right;
            }
            &.card-header-border {
                .right-header {
                    span {
                        &:first-child {
                            padding-right: unset;
                            padding-left: 20px;
                        }
                    }
                }
            }
        }
        &.full-card {
            left: 0;
            right: unset;
        }
        .card-body {
            .alert {
                svg {
                    margin-left: 4px;
                    margin-right: unset;
                }
            }
            .value-left {
                margin-left: 30px;
                margin-right: unset;
            }
            .square {
                margin-left: 5px;
                margin-right: unset;
            }
        }
    }
    .sidebar-toggle-btn {
        margin-right: 10px;
        margin-left: unset;
    }
    .modal-header {
        .close {
            margin-left: 0;
        }
    }
    .modal-footer {
        > :not(:first-child) {
            margin-right: 0.5rem;
            margin-left: 0;
        }
    }
    .form-inline {
        .form-group {
            .col-form-label {
                margin-left: 5px;
                margin-right: 0;
            }
        }
    }
    .breadcrumb-item {
        & + .breadcrumb-item {
            padding-right: 0.5rem;
            padding-left: unset;
            &::before {
                padding-left: 0.5rem;
                padding-right: unset;
            }
        }
    }
    code[class*='language-'],
    pre[class*='language-'] {
        text-align: right;
    }
    .input-group-prepend {
        margin-left: -1px;
        margin-right: unset;
    }
    .input-group > .form-control:not(:last-child),
    .rtl .input-group > .custom-select:not(:last-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    .input-group > .input-group-prepend > .btn,
    .input-group > .input-group-prepend > .input-group-text,
    .input-group > .input-group-append:not(:last-child) > .btn,
    .input-group > .input-group-append:not(:last-child) > .input-group-text,
    .input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
    .input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
    }
    .input-group > .input-group-append > .btn,
    .input-group > .input-group-append > .input-group-text,
    .input-group > .input-group-prepend:not(:first-child) > .btn,
    .input-group > .input-group-prepend:not(:first-child) > .input-group-text,
    .input-group > .input-group-prepend:first-child > .btn:not(:first-child),
    .input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    // calender
    .rbc-toolbar {
        direction: ltr;
    }
    // contact app
    .contact-filter {
        .input-range {
            direction: ltr;
        }
    }
    .contact-search {
        .form-group {
            &:before {
                right: 67px !important;
                left: unset !important;
            }
            &:after {
                right: 37px !important;
                left: unset !important;
            }
        }
    }
    .contact-options {
        li {
            .btn-category {
                padding-left: unset !important;
                padding-right: 40px !important;
            }
        }
    }
    .list-persons {
        .profile-mail {
            padding-left: 30px;
            .media {
                .media-body {
                    ul {
                        li {
                            + li {
                                padding-left: unset;
                                margin-left: unset;
                                margin-right: 5px;
                                padding-right: 5px;
                                border-left: unset;
                                border-right: 2px solid $light-color;
                            }
                        }
                    }
                }
            }
            .email-general {
                ul {
                    li {
                        > span {
                            float: left;
                        }
                    }
                }
            }
        }
    }
    // task app
    .date-details {
        > div {
            + div {
                margin-left: unset;
                margin-right: 10px;
            }
        }
    }
    .taskadd {
        table {
            tr {
                td {
                    &:first-child {
                        padding-left: unset;
                        padding-right: 30px;
                    }
                }
            }
        }
    }
    // toastr
    .Toastify__toast {
        direction: rtl;
    }
    // social app page css
    .socialprofile {
        .social-group {
            ul {
                li {
                    &:nth-child(4) {
                        margin-right: -22px;
                    }
                }
            }
        }
    }
    .social-status {
        form {
            .form-group {
                .form-control-plaintext {
                    padding-right: 15px;
                    padding-left: unset;
                }
            }
        }
        .media {
            .social-status {
                right: 35px;
                left: unset;
            }
        }
    }
    .social-chat {
        .other-msg {
            margin-right: 40px;
            margin-left: unset;
        }
        .media-body {
            &:before {
                left: 100%;
                right: unset;
                border-left: 7px solid $semi-dark;
                border-right: unset;
            }
            &:after {
                left: 100%;
                right: unset;
                border-left: 7px solid $white;
                border-right: unset;
            }
        }
    }
    .timeline-content {
        .comment-number {
            i {
                margin-left: 20px;
                margin-right: unset;
            }
        }
    }
    .photos {
        ul {
            li {
                margin-right: unset;
                margin-left: 15px;
                &:nth-child(3n) {
                    margin-left: 0;
                }
            }
        }
    }
    .avatar-showcase {
        .friend-pic {
            margin-left: 8px;
            margin-right: unset;
        }
        .pepole-knows {
            ul {
                li {
                    margin-left: 21px;
                    margin-right: unset;
                    &:last-child {
                        margin-left: 0;
                    }
                }
            }
        }
    }
    .user-profile {
        .hovercard {
            .user-image {
                .share-icons {
                    left: 100px;
                    right: unset;
                    li {
                        margin-left: 10px;
                        margin-right: unset;
                        &:last-child {
                            margin-left: 0;
                        }
                    }
                }
            }
        }
    }
    // faq page css
    .faq-form {
        .search-icon {
            left: 12px;
            right: unset;
        }
    }
    .faq-widgets {
        .media-body {
            p {
                padding-left: 20px;
                padding-right: unset;
            }
        }
    }
    .header-faq,
    .faq-title {
        text-align: right;
    }
    .faq-accordion {
        .faq-header {
            svg {
                left: 30px;
                right: unset;
            }
        }
        .card {
            .btn-link {
                svg {
                    margin-left: 10px;
                    margin-right: unset;
                }
            }
        }
    }
    .navigation-option {
        ul {
            li {
                padding: 15px 0 15px 15px;
                a {
                    padding-right: 40px;
                    padding-left: unset;
                    svg {
                        right: 15px;
                        left: unset;
                    }
                }
            }
        }
    }
    .updates-faq {
        margin-right: unset;
        margin-left: 30px;
    }
    // knowledgebase page css
    .browse {
        .browse-articles {
            span {
                svg {
                    margin-left: 10px;
                    margin-right: unset;
                }
            }
        }
    }
    .search-knowledge {
        input {
            padding-right: 10px !important;
        }
    }
    // learning app page css
    .categories {
        .learning-header {
            padding-right: 30px;
            padding-left: unset;
        }
    }
    // internationalization page css
    .main {
        .langChoice {
            left: 30px;
            right: unset;
        }
    }
    .pricing-block {
        svg {
            direction: ltr;
        }
    }
    // chart peity css
    p {
        &.data-attributes {
            svg {
                margin-left: 10px;
                margin-right: unset;
            }
        }
    }
    // avatars page css
    .avatars {
        .avatar {
            margin-left: 10px;
            margin-right: unset;
            &:last-child {
                margin-left: 0;
            }
            .status {
                left: 4px;
                right: unset;
            }
        }
    }
    .customers {
        &.avatar-group {
            margin-left: 30px;
            margin-right: unset;
        }
    }
    // dashboard css
    .dashboard-chat {
        .chat {
            .left-side-chat {
                .media-body {
                    p {
                        &:before {
                            right: unset;
                            left: 100%;
                            border-right: unset;
                            border-left: 7px solid $light-body-bg-color;
                        }
                    }
                }
            }
            .right-side-chat {
                .media-body {
                    p {
                        &:before {
                            right: 100%;
                            left: unset;
                            border-right: 7px solid $light-body-bg-color;
                            border-left: unset;
                        }
                    }
                }
            }
        }
    }
    .chat-footer {
        i {
            padding-right: unset;
            padding-left: 20px;
        }
        svg {
            margin-right: 20px;
            margin-left: unset;
        }
    }
    .logs-element {
        span {
            margin-right: 30px;
            margin-left: unset;
        }
        .circle-double-odd {
            &:before {
                left: unset;
                right: 0;
            }
        }
        .circle-double-even {
            &:before {
                left: unset;
                right: 0;
            }
        }
    }
    .recent-notification {
        .media {
            .media-body {
                padding-right: 50px;
                padding-left: unset;
                &:before {
                    left: unset;
                    right: 28px;
                }
            }
        }
    }
    .user-card {
        .online-user {
            &:before {
                left: unset;
                right: -30px;
            }
        }
        .user-badge {
            .badge {
                margin-right: unset;
                margin-left: 10px;
                &:last-child {
                    margin-left: 0;
                }
            }
        }
        .card-footer {
            > div {
                &:first-child {
                    border-left: 1px solid $light-gray;
                    border-right: unset;
                }
            }
        }
    }
    .alert-theme,
    .alert-copy {
        i {
            margin-right: 0 !important;
        }
        button {
            left: 30px !important;
            right: unset !important;
        }
    }
    .status-circle {
        right: 40px;
        left: unset;
    }
    // ecommerce dashboard page
    .ecommerce-knob {
        input {
            margin-right: -231px !important;
            margin-left: unset !important;
        }
    }
    .todo {
        .todo-list-wrapper {
            .todo-list-footer {
                .card-header-add {
                    right: unset;
                    left: 0;
                }
            }
        }
    }
    // university dashboard page
    .buttons-right {
        .right-header {
            &:first-child {
                margin-left: 10px;
                margin-right: unset;
            }
        }
    }
    .feather-main {
        .feather-icon-block {
            margin-left: 30px;
            margin-right: unset;
        }
    }
    .small-bar {
        .flot-chart-container {
            .ct-chart {
                left: -14px;
                right: unset;
            }
        }
    }
    .professor-table {
        table {
            tbody {
                tr {
                    td {
                        label {
                            .radio_animated {
                                margin-left: 7px;
                                margin-right: unset;
                            }
                        }
                    }
                }
            }
        }
    }
    .notifiaction-media {
        .media {
            .media-body {
                h6 {
                    padding-right: 30px;
                    padding-left: unset;
                }
            }
        }
    }
    .statistics {
        text-align: left;
    }
    .card-gradient {
        .knob-header {
            .d-inline-block {
                right: unset;
                left: 29px;
            }
        }
    }
    .topper-lists {
        table {
            tbody {
                tr {
                    td {
                        padding-right: 3px;
                        padding-left: unset;
                        .d-inline-block {
                            .check-dot {
                                left: 28px;
                                right: unset;
                            }
                        }
                    }
                }
            }
        }
    }
    // bitcoin dashboard css
    .bitcoin-graph {
        .top-bitcoin {
            margin-right: 10px;
            margin-left: unset;
        }
        .media-body {
            .bitcoin-content {
                .bitcoin-numbers {
                    margin-right: unset;
                    margin-left: 20px;
                    &:last-child {
                        margin-left: 0;
                    }
                }
            }
        }
    }
    .markets {
        .media-body {
            margin-right: 20px;
            margin-left: unset;
        }
    }
    .customers {
        ul {
            li {
                + li {
                    margin-right: -16px;
                    margin-left: unset;
                }
                &:nth-child(4) {
                    margin-right: 5px;
                    margin-left: unset;
                }
            }
        }
    }
    .show-value-top {
        .value-third {
            margin-right: 30px;
            margin-left: unset;
        }
    }
    .chat-box {
        .chat-right-aside {
            .chat {
                .chat-msg-box {
                    .my-message {
                        border-top-left-radius: 10px;
                        border-top-right-radius: 0;
                    }
                    .other-message {
                        border-top-left-radius: 0;
                        border-top-right-radius: 10px;
                    }
                }
            }
        }
        .bitcoin-chat {
            .chat {
                .bitcoin-message {
                    .smiley-box {
                        border-top-right-radius: 0.25rem;
                        border-bottom-right-radius: 0.25rem;
                        border-top-left-radius: 0;
                        border-bottom-left-radius: 0;
                    }
                    .text-box {
                        .input-group-append {
                            .btn {
                                border-top-left-radius: 0.25rem;
                                border-bottom-left-radius: 0.25rem;
                            }
                        }
                    }
                }
            }
        }
    }
    // server dashboard css
    .server-widgets {
        .media {
            .top-server {
                margin-right: 10px;
                margin-left: unset;
            }
        }
    }
    .server-chart {
        margin-left: unset;
        margin-right: -16px;
    }
    // project dashboard css
    .theme-tab {
        .tab-title {
            li {
                svg {
                    margin-left: 5px;
                    margin-right: unset;
                }
            }
        }
    }
    // knob chart css
    .knob-chart {
        .chart-clock-main {
            .clock-small {
                right: 10px;
                left: unset;
            }
        }
    }
    // general widgets css
    .weather-widget-two {
        .top-bg-whether {
            left: -28px;
            right: unset;
        }
        .bottom-whetherinfo {
            svg {
                left: unset;
                right: -35px;
            }
        }
    }
    .mobile-clock-widget {
        .bg-svg {
            left: unset;
            right: -25px;
        }
    }
    .cal-date-widget {
        .cal-info {
            .pr-3 {
                padding-right: 1rem !important;
                padding-left: unset !important;
            }
        }
    }
    // to-do css
    .todo {
        .todo-list-wrapper {
            .mark-all-tasks {
                .btn-label {
                    margin-left: 5px;
                    margin-right: unset;
                }
                left: 30px;
                right: unset;
            }
            #todo-list {
                li {
                    .task-container {
                        .task-action-btn {
                            text-align: left;
                        }
                    }
                }
            }
        }
        .notification-popup {
            left: 10px;
            right: unset;
        }
    }
    .todo-options {
        .badges-todo {
            h6 {
                margin-left: 10px;
                margin-right: unset;
                &:last-child {
                    margin-left: 0;
                }
            }
        }
    }
    .todo-list-btns {
        .dropdown-basic {
            .btn-group {
                .form-group {
                    .checkbox {
                        padding-right: 14px;
                        border-top-right-radius: 25px;
                        border-bottom-right-radius: 25px;
                        padding-left: unset;
                        border-top-left-radius: unset;
                        border-bottom-left-radius: unset;
                    }
                }
            }
            .separated-btn {
                margin-right: -6px;
                margin-left: unset;
                .btn {
                    border-top-left-radius: 25px;
                    border-bottom-left-radius: 25px;
                }
            }
        }
    }
    .search-todo {
        .dropdown-basic {
            .btn-group {
                margin-left: 18px;
                margin-right: unset;
            }
        }
    }
    //sticky note css
    .sticky-note {
        .note {
            float: right;
        }
    }
    // mega-menu css
    .mega-menu {
        .list-unstyled {
            div {
                a {
                    padding: 8px 0 8px 35px;
                    &:hover {
                        padding: 8px 10px 8px 35px;
                    }
                }
            }
        }
    }
    #testimonial {
        .owl-stage-outer {
            .owl-stage {
                .owl-item {
                    .slide--item {
                        .media {
                            .mr-3,
                            .mx-3 {
                                margin-right: 1rem !important;
                                margin-left: unset !important;
                            }
                        }
                    }
                }
            }
        }
    }
    .needs-validation,
    .form-row {
        .input-group > .form-control:not(:last-child),
        .input-group > .custom-select:not(:last-child) {
            border-top-left-radius: 0.25rem;
            border-bottom-left-radius: 0.25rem;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
    }
    .form-check {
        padding-right: 1.25rem;
        padding-left: unset;
    }
    .mega-menu {
        .default-according {
            .card {
                .btn-link {
                    text-align: right;
                }
            }
            &.style-1 {
                button[aria-expanded='true'],
                button[aria-expanded='false'] {
                    &:before {
                        right: unset;
                        left: 20px;
                    }
                }
            }
        }
    }
    // email css
    .email-wrap {
        .row {
            .col-xl-6 {
                padding-right: 0;
                padding-left: 15px;
            }
            .col-xl-3 {
                + .col-xl-3 {
                    padding-left: 0;
                    padding-right: 15px;
                }
            }
        }
        .email-app-sidebar {
            .main-menu {
                & > li {
                    text-align: right;
                    a {
                        i {
                            margin-right: unset;
                            margin-left: 10px;
                        }
                    }
                }
            }
            ul {
                padding-right: 0;
            }
        }
        .email-content {
            .email-top {
                .user-emailid {
                    &:after {
                        float: left;
                    }
                }
            }
        }
        .email-right-aside {
            .email-body {
                .pr-0 {
                    padding-right: unset !important;
                }
            }
            .radius-left {
                border-top-right-radius: 0 !important;
                border-bottom-right-radius: 0 !important;
                border-radius: 4px;
            }
        }
    }
    // buttons css
    .btn-group-showcase {
        .btn-group {
            margin-left: 20px;
            margin-right: unset;
        }
    }
    .btn-showcase {
        .btn {
            margin-right: unset;
            margin-left: 18px;
        }
    }
    .btn-square {
        &.dropdown-toggle {
            border-top-left-radius: 0.25rem !important;
            border-bottom-left-radius: 0.25rem !important;
            border-top-right-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
        }
    }
    #customer-review {
        .owl-item {
            img {
                order: 2;
            }
        }
    }
    .options {
        > div {
            margin: 0 0 8px 8px;
        }
    }
    // modal page
    .modal-dialog {
        .modal-content {
            .modal-body {
                text-align: right;
                p {
                    a {
                        margin-left: 0;
                    }
                }
            }
        }
    }
    // forms page
    .was-validated {
        .custom-control {
            padding-right: 1.5rem;
            padding-left: unset;
            .custom-control-label {
                position: relative;
                &::before,
                &::after {
                    right: -25px;
                    left: unset;
                }
            }
        }
    }
    .drag-box {
        fieldset {
            .component {
                .form-group {
                    .text-lg-left {
                        text-align: right !important;
                    }
                    .input-group {
                        .input-group-prepend {
                            .btn,
                            .input-group-text {
                                border-top-left-radius: 0;
                                border-bottom-left-radius: 0;
                                border-top-right-radius: 0.25rem;
                                border-bottom-right-radius: 0.25rem;
                            }
                            .btn-right,
                            .checkbox-radius {
                                border-top-left-radius: 0.25rem;
                                border-bottom-left-radius: 0.25rem;
                                border-top-right-radius: 0;
                                border-bottom-right-radius: 0;
                            }
                        }
                        #prependedcheckbox {
                            border-top-right-radius: 0 !important;
                            border-bottom-right-radius: 0 !important;
                        }
                        #appendedcheckbox {
                            border-right-color: $light-semi-gray;
                        }
                        #buttondropdown {
                            margin-right: unset;
                            margin-left: -1px;
                        }
                    }
                }
            }
        }
    }
    .form-horizontal {
        &.theme-form {
            fieldset {
                .tab-content {
                    .tab-pane {
                        .component {
                            .form-group {
                                .text-lg-left {
                                    text-align: right !important;
                                }
                                .input-group {
                                    .input-group-prepend {
                                        .btn,
                                        .input-group-text {
                                            border-top-left-radius: 0;
                                            border-bottom-left-radius: 0;
                                            border-top-right-radius: 0.25rem;
                                            border-bottom-right-radius: 0.25rem;
                                        }
                                        .btn-right,
                                        .checkbox-radius {
                                            border-top-left-radius: 0.25rem;
                                            border-bottom-left-radius: 0.25rem;
                                            border-top-right-radius: 0;
                                            border-bottom-right-radius: 0;
                                        }
                                    }
                                    #prependedcheckbox {
                                        border-top-right-radius: 0 !important;
                                        border-bottom-right-radius: 0 !important;
                                    }
                                    #appendedcheckbox {
                                        border-right-color: $light-semi-gray;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    // range slider
    .rangeslider__labels {
        .rangeslider__label-item {
            left: 0;
        }
    }
    .rangeslider,
    .react-stickies-wrapper {
        direction: ltr;
    }
    // compact small page
    .page-wrapper.compact-page {
        .page-main-header {
            margin-right: 75px;
            &.open {
                margin-right: 0;
            }
        }
        .page-body-wrapper.sidebar-hover {
            .logo-wrapper {
                a {
                    transform: translate(-88px);
                }
            }
            .page-sidebar {
                transform: translate(180px);
                .sidebar-user,
                .sidebar-header {
                    text-align: left !important;
                }
                &:hover {
                    transform: translate(0px);
                    .sidebar-user {
                        text-align: center !important;
                    }
                }
                &.open {
                    ~ .page-body,
                    ~ .footer {
                        margin-right: 0 !important;
                    }
                }
            }
            .page-body,
            .footer {
                margin-left: unset !important;
                margin-right: 75px !important;
            }
        }
        .sidebar-hover {
            .page-sidebar {
                &:hover {
                    .sidebar-menu {
                        .sidebar-header {
                            text-align: right !important;
                        }
                    }
                }
            }
        }
    }
    // compact page
    .page-wrapper {
        .page-body-wrapper.sidebar-icon {
            &.sidebar-close {
                .page-sidebar {
                    ~ .page-body {
                        margin-right: 0 !important;
                        transition: all 0.3s ease;
                    }
                }
                .page-sidebar-open {
                    right: -150px;
                    left: unset;
                    transition: all 0.5s ease;
                }
            }
            .page-sidebar {
                .sidebar-menu {
                    li {
                        &:hover {
                            .single-header {
                                margin-left: unset;
                                margin-right: 115px;
                                text-align: right;
                            }
                            .sidebar-submenu {
                                margin-left: unset;
                                margin-right: 115px;
                                text-align: right;
                            }
                        }
                        a {
                            &:hover {
                                + {
                                    ul {
                                        margin-left: unset;
                                        margin-right: 115px;
                                        text-align: right;
                                    }
                                }
                            }
                        }
                    }
                    .sidebar-submenu {
                        > {
                            li {
                                > {
                                    a {
                                        > .fa-circle {
                                            left: unset;
                                            right: 20px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                ~ .page-body {
                    margin-right: 150px !important;
                    margin-left: 0;
                    transition: all 0.3s ease;
                }
                &.open {
                    ~ .page-body {
                        margin-right: 0 !important;
                    }
                }
            }
        }
        &.compact-wrapper {
            .page-main-header {
                margin-right: 145px;
                margin-left: unset;
                box-shadow: -2px 0 3px 1px rgba(68, 102, 242, 0.1);
                &.open {
                    margin-right: 0;
                }
            }
            .page-body-wrapper.sidebar-icon {
                .footer {
                    margin-left: unset;
                    margin-right: 145px;
                }
            }
        }
    }
    .page-wrapper .sidebar-icon {
        .page-sidebar {
            left: unset;
            right: 0;
            ~ .page-body {
                margin-left: unset;
            }
        }
    }
    // vertical page
    .vertical {
        .page-main-header {
            margin-right: 0 !important;
            margin-left: unset !important;
        }
    }
    .vertical-menu-mt {
        .page-header {
            div {
                .pull-right {
                    float: left;
                }
            }
        }
    }
    .sm {
        li {
            direction: rtl;
            text-align: right;
        }
        .mobile-back {
            .pl-2 {
                padding-right: 4px !important;
                padding-left: unset !important;
            }
        }
    }
    .vertical-menu-main {
        #main-nav {
            #main-menu {
                li {
                    a {
                        svg {
                            margin-left: 8px;
                            margin-right: unset;
                        }
                    }
                }
            }
        }
    }
    .pixelstrap {
        a {
            .sub-arrow {
                right: auto;
                left: 5px;
            }
        }
    }
    @media (min-width: 1200px) {
        .pixelstrap {
            ul:not(.mega-menu) li a:before {
                left: unset;
                right: 20px;
            }
            a {
                .sub-arrow {
                    right: unset;
                    left: 3px;
                }
            }
        }
    }
    // search page
    .search-page {
        .nav i {
            margin-left: 10px;
            margin-right: unset;
        }
    }
    .product-stts {
        display: inline-block;
        .ml-1 {
            margin-right: 0.25rem !important;
            margin-left: unset !important;
        }
    }
    .search-form input {
        padding: 10px 70px 10px 10px;
        text-align: right;
    }
    // icons page
    .icon-hover-bottom {
        .icon-title {
            text-align: right;
        }
        .icon-popup {
            .icon-last {
                .form-inline {
                    .form-group {
                        .btn {
                            margin-right: 15px;
                            margin-left: unset;
                        }
                    }
                }
            }
            .close-icon {
                left: 10px;
                right: unset;
            }
        }
    }
    .icon-lists {
        div {
            i {
                margin: 0 10px 0 15px;
            }
        }
        ul {
            padding-right: 0;
        }
    }
    ul {
        &.icon-lists {
            padding-right: 30px;
        }
    }
    // customizer
    .floated-customizer-btn {
        &.third-floated-btn {
            left: 35px;
            right: unset;
        }
        & > span {
            margin-right: 10px;
        }
    }
    .floated-customizer-panel {
        text-align: right;
        left: -85px;
        right: unset;
        &.active {
            left: 35px;
            right: unset;
        }
        .close-customizer-btn {
            left: 30px;
            right: unset;
        }
        ul {
            padding-right: 0;
        }
    }
    .radio {
        label {
            padding-left: 0;
            padding-right: 15px;
            &::before {
                right: 0;
                margin-right: -20px;
                left: unset;
            }
        }
    }
    // sidebar main page
    .page-wrapper {
        .page-body-wrapper {
            .page-sidebar {
                .sidebar-menu {
                    text-align: right;
                    li {
                        &.active {
                            > a {
                                > .fa-angle-right {
                                    vertical-align: 7px;
                                }
                            }
                            .sidebar-submenu {
                                margin-left: unset;
                                margin-right: 25px;
                            }
                        }
                    }
                    & > li {
                        & > a {
                            i {
                                margin-left: 7px;
                                margin-right: unset;
                                &.pull-right {
                                    left: 20px;
                                    right: unset;
                                }
                            }
                            &:hover {
                                padding-right: 3px;
                                padding-left: unset;
                            }
                        }
                        .badge {
                            margin-right: 42px;
                            margin-left: unset;
                        }
                    }
                    .sidebar-submenu {
                        padding: 0;
                        text-align: right;
                        margin-right: 5px;
                        margin-left: unset;
                        & > li {
                            & > a {
                                text-align: right;
                                padding-right: 10px;
                                padding-left: unset;
                                > .fa-circle {
                                    right: 0;
                                    left: unset;
                                }
                            }
                        }
                    }
                    .sidebar-header {
                        svg {
                            margin-left: 14px;
                            margin-right: unset !important;
                        }
                    }
                }
                .main-header-left {
                    box-shadow: 2px 1px 3px 1px rgba(68, 102, 242, 0.1);
                }
                & ~ .page-body {
                    margin-right: 255px;
                    margin-left: unset;
                }
                &.open {
                    margin-right: calc(-260px);
                    margin-left: unset;
                    & ~ .page-body {
                        margin-right: 0;
                    }
                    & ~ footer {
                        margin-right: 0;
                    }
                }
            }
            .page-header {
                .row {
                    h3 {
                        text-align: right;
                    }
                }
            }
            .footer-fix {
                margin-right: 250px;
                margin-left: unset;
            }
        }
    }
    // right-sidebar page
    .right-sidebar {
        left: -285px;
        right: unset;
        .modal-header {
            .modal-title {
                text-align: right;
            }
        }
        .friend-list-search {
            .fa {
                left: 35px;
                right: unset;
            }
        }
        &.show {
            left: 0;
        }
    }
    // page main header
    .page-main-header {
        margin-right: 255px;
        margin-left: unset;
        .main-header-right {
            .nav-right {
                text-align: left;
                > ul {
                    > li {
                        .dot {
                            left: 17px;
                            right: unset;
                        }
                        .media {
                            .dotted-animation {
                                left: -3px;
                                right: unset;
                                .main-circle {
                                    left: 6px;
                                    right: unset;
                                }
                                .animate-circle {
                                    left: -4px;
                                    right: unset;
                                }
                            }
                        }
                        &.onhover-dropdown {
                            &:hover {
                                .onhover-show-div {
                                    &:before {
                                        right: 10px;
                                        left: unset;
                                    }
                                    &:after {
                                        right: 10px;
                                        left: unset;
                                    }
                                }
                            }
                            &:last-child {
                                padding-left: 0;
                                padding-right: 20px;
                                border-right: none;
                            }
                        }
                        &:nth-child(5) {
                            padding-left: 20px;
                            padding-right: 0;
                            border-right: none;
                            border-left: 1px solid $light-semi-gray;
                        }
                        &:first-child {
                            border-right: none;
                            .search-form {
                                .form-group {
                                    &:after {
                                        right: 22px;
                                        left: unset;
                                    }
                                    &:before {
                                        right: 51px;
                                        left: unset;
                                    }
                                }
                            }
                        }
                        text-align: right;
                        border-right: 1px solid $light-semi-gray;
                        border-left: unset;
                        i {
                            &.ml-2 {
                                margin-right: 0.5rem !important;
                                margin-left: unset !important;
                            }
                        }
                    }
                }
                .language-dropdown {
                    text-align: right;
                    li {
                        a {
                            i {
                                margin-left: 10px;
                            }
                        }
                    }
                }
                .profile-dropdown {
                    li {
                        a {
                            svg {
                                margin-right: unset;
                                margin-left: 10px;
                            }
                        }
                    }
                }
            }
        }
        &.open {
            margin-right: 0;
        }
    }
    ul {
        &.notification-dropdown {
            &.onhover-show-div {
                right: initial;
                left: -30px;
                padding: 0;
                &:before {
                    right: inherit !important;
                    left: 35px !important;
                }
                &:after {
                    right: inherit !important;
                    left: 35px !important;
                }
                li {
                    margin-right: 0 !important;
                    span {
                        &.badge {
                            margin-left: unset !important;
                        }
                        svg {
                            margin-right: unset;
                            margin-left: 10px;
                        }
                    }
                    .notification-icon {
                        margin-left: 20px;
                        margin-right: unset;
                    }
                }
            }
        }
    }
    // alert page
    .alert {
        i {
            margin-left: 5px;
            margin-right: 0;
        }
    }
    .inverse {
        padding: 13px 65px 13px 20px;
        i {
            left: unset;
            right: 0;
        }
    }
    @each $var in $alert-name {
        $i: index($alert-name, $var);
        .alert-#{$var}.inverse {
            &:before {
                border-right: 7px solid nth($alert-color, $i);
                right: 54px;
                left: unset;
                border-left: unset;
            }
        }
    }
    .alert-dismissible {
        .close {
            left: 0;
            right: unset;
            span {
                padding-left: 0;
                padding-right: unset;
            }
        }
    }
    .icofont {
        &.icofont-truck {
            display: inline-block;
        }
    }
    // blog page
    .blog-box {
        .blog-details {
            padding-left: 20px;
            text-align: right;
            .blog-social {
                padding-right: 0;
                li {
                    padding-left: 40px;
                    padding-right: unset;
                    &:first-child {
                        border-left: 1px solid #777777;
                        border-right: unset;
                        padding-left: 40px;
                        padding-right: unset;
                    }
                    & + li {
                        margin-right: 30px;
                        margin-left: unset;
                        padding-left: 0;
                    }
                }
            }
        }
        .blog-details-main {
            .blog-social {
                li {
                    border-left: 1px solid;
                    border-right: unset;
                    &:last-child {
                        border-left: none;
                    }
                }
            }
        }
        .blog-date {
            span {
                font-size: 36px;
                font-weight: 500;
                padding-left: 5px;
            }
        }
    }
    .comment-box {
        .media {
            img {
                margin-left: 45px;
                margin-right: unset;
            }
            h6 {
                text-align: right;
            }
        }
        ul {
            ul {
                margin-right: 135px;
                margin-left: unset;
                padding-right: 0;
            }
        }
        .comment-social {
            text-align: left;
            li {
                padding-left: 0;
                padding-right: 20px;
                &:first-child {
                    border-left: 1px solid $light-semi-gray;
                    border-right: unset;
                    padding-right: 0;
                    padding-left: 20px;
                }
            }
        }
    }
    // chat page
    .call-chat-body {
        .chat-box {
            .chat-right-aside {
                .chat {
                    .chat-history {
                        .caller-img {
                            &.pl-0 {
                                padding-left: 15px !important;
                            }
                        }
                    }
                }
            }
        }
    }
    .chat-box {
        .user-image {
            float: right;
            margin-left: 5px;
            margin-right: 0;
        }
        .people-list {
            .search {
                i {
                    left: 10px;
                    right: unset;
                }
            }
        }
        .about {
            float: right;
            padding-right: 10px;
            padding-left: unset;
            text-align: right;
        }
        .chat-menu {
            border-right: 1px solid $light-color;
            border-left: unset;
            .nav {
                padding-right: 0;
            }
            &.pl-0 {
                padding-left: 15px !important;
            }
        }
        .chat-right-aside {
            .chat {
                .chat-message {
                    .smiley-box {
                        margin-left: 0.5rem;
                        margin-right: unset;
                    }
                }
            }
            &.pr-0 {
                padding-right: 15px !important;
            }
        }
        .chat {
            .chat-message {
                .text-box {
                    .input-group-append {
                        margin-right: -2px;
                    }
                }
            }
        }
    }
    .chat-msg-box {
        ul {
            padding-right: 0;
        }
    }
    .chat-left-aside {
        .status-circle {
            left: unset;
            right: 40px;
        }
    }
    .chat-right-aside {
        .chat {
            .chat-header {
                .chat-menu-icons {
                    padding-left: 0;
                    padding-right: unset;
                    &.float-sm-right {
                        float: left !important;
                    }
                }
                img {
                    float: right;
                }
            }
        }
    }
    // cke editor page
    .cke_toolbar,
    .cke_toolgroup,
    a.cke_button,
    .cke_combo_text {
        float: right;
    }
    .cke_reset_all,
    .cke_reset_all *,
    .cke_reset_all a,
    .cke_reset_all textarea {
        text-align: right;
    }
    .cke_combo_text {
        padding-left: unset;
        padding-right: 10px;
    }
    #editor1 {
        #cke_editor1 {
            #cke_1_contents {
                iframe {
                    html {
                        direction: rtl !important;
                    }
                }
            }
        }
    }
    // coming soon page
    #clockdiv {
        ul {
            padding-right: 0;
        }
    }
    // social app page
    .custom-card {
        .card-footer {
            & > div {
                & + div {
                    border-right: 1px solid $light-semi-gray;
                    border-left: unset;
                }
            }
        }
    }
    .card-social {
        padding-right: 0;
    }
    .card-absolute {
        .card-header {
            right: 15px;
            left: unset;
        }
    }
    // datatables page
    table {
        &.dataTable {
            thead {
                & > tr {
                    & > th {
                        padding-left: 30px;
                        padding-right: 12px;
                        &:before {
                            left: 1em !important;
                            right: unset;
                        }
                        &:after {
                            left: 0.5em !important;
                            right: unset;
                        }
                    }
                }
            }
        }
    }
    table.dataTable thead .sorting:before,
    table.dataTable thead .sorting_asc:before,
    table.dataTable thead .sorting_desc:before,
    table.dataTable thead .sorting_asc_disabled:before,
    table.dataTable thead .sorting_desc_disabled:before {
        left: 1em !important;
        right: unset;
    }
    .dataTables_scrollHeadInner {
        padding-right: unset !important;
        padding-left: 0 !important;
    }
    .dt-plugin-buttons {
        button {
            &.m-r-10 {
                margin-left: 10px;
                margin-right: 0;
            }
        }
    }
    // datetime picker page
    .datetime-picker {
        .theme-form {
            .form-group {
                label {
                    text-align: left !important;
                }
                .input-group-text {
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                }
                .input-group {
                    .form-control {
                        border-radius: 0.25rem;
                        border-top-left-radius: 0;
                        border-bottom-left-radius: 0;
                    }
                }
            }
        }
    }
    // dropdown page
    .dropdown-basic {
        .btn {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-top-right-radius: 0.25rem !important;
            border-bottom-right-radius: 0.25rem !important;
        }
        .separated-btn {
            .btn {
                border-radius: 0.25rem;
                border-top-right-radius: 0 !important;
                border-bottom-right-radius: 0 !important;
            }
        }
    }
    // error page
    .error-wrapper {
        .col-md-8 {
            &.offset-md-2 {
                margin: 0 auto !important;
            }
        }
    }
    // product page
    .product-filter {
        .color-selector {
            ul {
                li {
                    + li {
                        margin-right: 5px;
                        margin-left: unset;
                    }
                }
            }
        }
    }
    .product-price {
        del {
            padding-left: 20px;
            padding-right: 0;
        }
    }
    .product-wrapper {
        .product-sidebar {
            .filter-section {
                .card {
                    .left-filter {
                        .product-filter {
                            .input-range {
                                direction: ltr;
                            }
                        }
                    }
                }
            }
        }
    }
    .product-box {
        .ribbon-bookmark.ribbon-vertical-left.ribbon-info:before,
        .ribbon-bookmark.ribbon-vertical-right.ribbon-info:before {
            border-left-color: #0068a5;
        }
        .ribbon-bookmark.ribbon-vertical-left:before,
        .ribbon-bookmark.ribbon-vertical-right:before {
            right: 0;
            left: unset;
            border-left: 15px solid #2a3142;
        }
        .ribbon-bookmark.ribbon-info:before {
            border-left-color: transparent;
        }
        .ribbon-warning.ribbon-clip:before {
            border-left-color: #ff850d;
            border-right-color: unset;
        }
        .ribbon-clip {
            right: -14px;
            left: unset;
            &:before {
                right: 0;
                left: unset;
            }
        }
    }
    .product-hover {
        ul {
            padding-right: 0;
        }
    }
    .product-modal {
        .product-details {
            .product-size {
                ul {
                    li {
                        + li {
                            margin-right: 10px;
                            margin-left: unset;
                        }
                    }
                }
            }
        }
    }
    .customer-review {
        .media {
            img {
                margin-left: 25px;
                margin-right: 0;
            }
        }
    }
    .nav {
        padding-right: 0;
    }
    .custom-file-label {
        &::after {
            left: 0;
            right: unset;
        }
    }
    // general widgets page
    .static-top-widget .media-body {
        padding-right: 30px;
        padding-left: unset;
        .icon-bg {
            left: -20px;
            right: unset;
        }
    }
    .widget-joins {
        .media {
            .details {
                border-right: 1px solid $light-semi-gray;
                border-left: unset;
            }
            .media-body {
                text-align: right;
            }
        }
    }
    .weather-widget-two {
        .bottom-whetherinfo {
            .whether-content {
                text-align: left;
            }
        }
    }
    // image cropper page
    .img-cropper {
        .btn-group {
            margin-right: 0;
            margin-left: 10px;
            button {
                &:last-child {
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                    border-top-left-radius: 0.25rem;
                    border-bottom-left-radius: 0.25rem;
                }
                &:first-child {
                    border-top-right-radius: 0.25rem;
                    border-bottom-right-radius: 0.25rem;
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                }
            }
        }
    }
    .docs-toggles {
        .btn-group {
            .btn {
                border-radius: 0;
                &:last-child {
                    border-top-left-radius: 0.25rem;
                    border-bottom-left-radius: 0.25rem;
                }
                &:first-child {
                    border-top-right-radius: 0.25rem;
                    border-bottom-right-radius: 0.25rem;
                }
            }
        }
    }
    .btn-group {
        > .btn:not(:last-child):not(.dropdown-toggle) {
            > .btn-group:not(:last-child) > .btn {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
        }
    }
    // landing page
    .landing-main {
        .landing-left {
            padding-right: 100px;
            padding-left: 0;
        }
    }
    .list-group {
        padding-right: 0;
        i,
        img {
            margin-left: 10px;
            margin-right: 0;
        }
    }
    // authentication page
    .authentication-box {
        text-align: right;
    }
    // mega options page
    .mega-horizontal {
        .offset-sm-3 {
            margin-right: 25%;
            margin-left: unset;
        }
    }
    // navs page
    .navs-icon {
        svg {
            margin-left: 10px;
            margin-right: unset;
        }
        .fa {
            margin-left: 10px;
            margin-right: unset;
        }
    }
    .nav-list {
        .nav-list-disc {
            text-align: right;
            padding-right: 0;
        }
    }
    .pl-navs-inline {
        padding-right: 30px !important;
        padding-left: 0 !important;
    }
    .onhover-dropdown.navs-dropdown:hover .onhover-show-div:before {
        left: 77px;
        right: unset;
    }
    .onhover-dropdown.navs-dropdown:hover .onhover-show-div:after {
        left: 77px;
        right: unset;
    }
    .navs-dropdown {
        .onhover-show-div {
            .navs-icon {
                li {
                    text-align: right;
                }
            }
        }
    }
    .navs-icon.default-according.style-1 {
        li {
            button[aria-expanded='true'] {
                &:before {
                    left: 2px;
                    right: unset;
                }
            }
            button[aria-expanded='false']:before {
                left: 2px;
                right: unset;
            }
        }
    }
    // search page css
    .search-form {
        .form-group {
            &:after {
                right: 53px;
                left: unset;
            }
            &:before {
                right: 82px;
                left: unset;
            }
        }
    }
    // order history page css
    .order-history {
        table {
            tbody {
                tr {
                    td {
                        .product-name {
                            .order-process {
                                .order-process-circle {
                                    &:before {
                                        right: -15px;
                                        left: unset;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            th,
            td {
                &:first-child {
                    text-align: right;
                }
            }
        }
    }
    // product-page
    .product-page-details {
        span {
            padding-right: 15px;
            padding-left: 0;
        }
    }
    .m-checkbox-inline {
        label {
            margin-left: 20px;
            margin-right: 0;
        }
    }
    .form-label-align-right {
        label {
            text-align: left;
        }
    }
    // ribbons page
    .ribbon-left {
        right: auto;
        left: -2px;
    }
    .ribbon-vertical-left {
        right: auto;
        left: 12px;
    }
    .ribbon-vertical-right {
        right: auto;
        left: 12px;
    }
    // search page
    .star-ratings {
        ul {
            &.search-info {
                padding-right: 0;
                li {
                    & + li {
                        border-right: 1px solid #a5afc6;
                        border-left: unset;
                        padding-right: 8px;
                        padding-left: 0;
                        margin-right: 8px;
                        margin-left: 0;
                    }
                }
            }
        }
    }
    // select 2 page
    .CodeMirror-scroll {
        margin-left: -30px;
        margin-right: unset;
    }
    .primary-color,
    .yellow-color {
        ul {
            padding-right: 0;
        }
    }
    // steps page
    .u-step-desc {
        text-align: right;
    }
    .u-step-number {
        right: 20px;
        left: unset;
        & ~ .u-step-desc {
            margin-right: 50px;
        }
    }
    .u-pearl {
        &:after {
            left: 0;
            right: unset;
        }
        &:before {
            right: 0;
            left: unset;
        }
    }
    .u-step-icon {
        float: right;
        margin-left: 0.5em;
        margin-right: unset;
    }
    // summernote page
    .list-icons {
        padding-right: 0;
    }
    .card-header.note-toolbar {
        .note-color {
            .dropdown-toggle {
                padding-right: 5px;
            }
        }

        .form-check {
            padding-right: 24px;
            padding-left: unset;
        }
        form {
            .custom-control {
                padding-right: 24px;
                padding-left: unset;
                .custom-control-label {
                    &::after {
                        right: 0;
                        left: unset;
                    }
                    &::before {
                        right: 0;
                        left: unset;
                    }
                }
            }
        }
    }
    // input group page
    .dropdown-toggle::after {
        margin-right: 0.255em;
        margin-left: unset;
    }
    // tabbed card page
    .tabbed-card {
        ul {
            left: 15px;
            right: unset;
        }
    }
    // checkbox & radios page
    .checkbox {
        label {
            padding-right: 16px;
            padding-left: unset;
            &::before {
                right: 0;
                left: unset;
                margin-right: -16px;
                margin-left: unset;
            }
        }
    }
    .radio {
        label {
            &::after {
                right: 5px;
                left: unset;
                margin-right: -20px;
                margin-left: unset;
            }
        }
    }
    // timeline page
    .cd-timeline-content {
        &::before {
            border-right: 7px solid $light-color;
            border-left-color: transparent;
        }
    }
    .cd-timeline-img {
        i {
            right: 40%;
            left: unset;
            margin-right: -12px;
            margin-left: unset;
        }
    }
    .cd-timeline-block {
        &:nth-child(even) {
            .cd-timeline-content {
                float: left;
                border-left: 7px solid #eeeeee;
                border-right: unset;
                .cd-date {
                    right: auto;
                    left: 122%;
                    text-align: left;
                }
                &::before {
                    right: auto;
                    left: 100%;
                    border-left-color: #eeeeee;
                    border-right-color: transparent;
                }
            }
        }
    }
    .cd-timeline-content {
        .cd-date {
            right: 122%;
            left: unset;
        }
    }
    .list-inline-item {
        &:not(:last-child) {
            margin-left: 0.5rem;
            margin-right: unset;
        }
    }
    .like-comment {
        ul {
            &.list-inline {
                padding-right: 0;
            }
        }
    }
    .typeahead {
        text-align: right;
    }
    .listing {
        ul {
            padding-right: 0;
            .icofont {
                float: right;
            }
        }
    }
    // gallery page
    .ril__toolbarLeftSide {
        padding-left: 0;
        padding-right: 20px;
    }
    .gallery {
        .hover-5 {
            img {
                margin-right: 30px;
            }
            &:hover {
                img {
                    margin-right: 0;
                }
            }
        }
    }
    .lg-outer {
        text-align: right;
    }
    .lg-toolbar .lg-icon {
        float: left;
    }
    #lg-counter {
        padding-right: 20px;
        padding-left: unset;
        float: right;
    }
    // chart widget page
    .chart-widget-top {
        .text-right {
            text-align: left !important;
        }
        .num {
            .ml-1 {
                margin-right: 0.25rem !important;
            }
        }
    }
    .bar-chart-widget {
        .earning-details {
            i {
                left: -240px;
            }
        }
        .bottom-content {
            .b-r-light {
                border-left: 1px solid #eeeeee !important;
                border-right: unset !important;
            }
            .num {
                .ml-1 {
                    margin-right: 0.25rem !important;
                }
            }
        }
    }
    // dashboard project page
    .chart-vertical-center {
        #myDoughnutGraph,
        #myPolarGraph {
            width: auto !important;
        }
    }
    .crm-activity {
        ul {
            &.dates {
                li {
                    + li {
                        border-right: 1px solid #ddd;
                        padding-right: 10px;
                        margin-right: 5px;
                        border-left: unset;
                        padding-left: unset;
                        margin-left: unset;
                    }
                }
            }
        }
    }
    div {
        &.dt-buttons {
            float: right;
        }
    }
    .dt-button-collection {
        left: -39px !important;
    }
    // dropzone page
    .dropzone {
        .dz-preview {
            .dz-error-mark,
            .dz-success-mark {
                right: 50%;
                margin-right: -27px;
                margin-left: unset;
                left: unset;
            }
        }
    }
    // footer page
    .page-wrapper {
        .page-body-wrapper {
            footer {
                margin-right: 255px;
                margin-left: unset;
                p {
                    i {
                        margin-right: 5px;
                        margin-left: unset;
                    }
                }
            }
        }
    }
    .footer-copyright {
        text-align: right;
    }
    .footer-links {
        text-align: left;
    }
    .page-item {
        &:first-child {
            .page-link {
                margin-right: 0;
                border-top-right-radius: 0.25rem;
                border-bottom-right-radius: 0.25rem;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        }
        &:last-child {
            .page-link {
                border-top-left-radius: 0.25rem;
                border-bottom-left-radius: 0.25rem;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
        }
    }
    // popover page
    .popover.top,
    .popover.bottom {
        > .arrow:after {
            margin-right: -10px;
            margin-left: unset;
        }
    }
    // typahead page
    .rbt {
        .rbt-menu {
            text-align: right;
        }
    }
    // call-chat page
    .follow {
        .text-md-right {
            text-align: left !important;
        }
    }
    // vector map page
    .jvectormap-zoomin,
    .jvectormap-zoomout,
    .jvectormap-goback {
        right: 10px;
        left: unset;
    }

    //customizer rtl css
    .customizer-links {
        right: unset;
        left: 0;
        &.open {
            right: unset;
            left: 330px;
            border-radius: 0 8px 8px 0;
        }
    }
    .customizer-contain {
        width: 330px;
        right: unset;
        left: -330px;
        &.open {
            right: unset;
            left: 0px;
        }
        .customizer-header {
            .icon-close {
                left: unset;
                right: 30px;
            }
        }
    }
    // dark only rtl css
    &.dark-only {
        .page-main-header {
            .main-header-right {
                .nav-right {
                    > ul {
                        > li {
                            border-right: 1px solid $dark-border-color;
                            border-left: unset !important;
                            &:first-child,
                            &:nth-child(5) {
                                border-right: none !important;
                            }
                            &:nth-child(5) {
                                border-left: 1px solid $dark-border-color !important;
                            }
                        }
                    }
                }
            }
        }
        // contact app
        .list-persons {
            .profile-mail {
                .media {
                    .media-body {
                        ul {
                            li {
                                + li {
                                    border-left: unset;
                                    border-right: 2px solid $dark-border-color;
                                }
                            }
                        }
                    }
                }
            }
        }
        .contacts-tabs {
            .nav-pills {
                border-left: 1px solid $dark-border-color;
                border-right: unset;
            }
        }
    }
    &.dark-sidebar-body-mix,
    &.dark-body-only {
        // contact app
        .list-persons {
            .profile-mail {
                .media {
                    .media-body {
                        ul {
                            li {
                                + li {
                                    border-left: unset;
                                    border-right: 2px solid $dark-border-color;
                                }
                            }
                        }
                    }
                }
            }
        }
        .contacts-tabs {
            .nav-pills {
                border-left: 1px solid $dark-border-color;
                border-right: unset;
            }
        }
    }
    // boxes layout rtl css
    .box-layout {
        &.page-wrapper {
            .page-main-header {
                padding-right: 250px;
                padding-left: unset;
                &.open {
                    padding-right: 0;
                }
            }
        }
    }

    // responsive css
    @media screen and (max-width: 1660px) {
        // video chat page
        .caller-img {
            left: unset;
            right: 15px;
        }
    }
    @media only screen and (max-width: 1366px) {
        .product-wrapper {
            .product-grid {
                .feature-products {
                    margin-right: 0;
                }
            }
        }
        .product-grid {
            margin-right: 0;
        }
        .d-none-productlist {
            margin-left: 10px;
            margin-right: unset;
        }
        .product-sidebar {
            transform: translateX(300px) scaleX(0);
        }
        .photos {
            ul {
                li {
                    &:nth-child(3n) {
                        margin-left: 15px;
                        margin-right: unset;
                    }
                }
            }
        }
        // chat page
        .chat-left-aside {
            .status-circle {
                left: unset;
                right: 22px;
            }
        }
    }
    @media (min-width: 1200px) {
        // chat page
        .chat-box {
            .pr-xl-0,
            .px-xl-0 {
                padding-left: 0 !important;
                padding-right: 15px !important;
            }
            .pl-xl-0,
            .px-xl-0 {
                padding-right: 0 !important;
                padding-left: 15px !important;
            }
        }
        // vertical page
        .pixelstrap {
            ul {
                a {
                    &:hover,
                    &:focus,
                    &:active,
                    &.highlighted {
                        .sub-arrow {
                            left: 3px;
                            right: unset;
                        }
                    }
                }
            }
        }
    }
    @media only screen and (max-width: 1199px) {
        .user-profile {
            .hovercard {
                .user-image {
                    .share-icons {
                        left: 45px;
                    }
                }
            }
        }
        .email-wrap {
            .row {
                .col-xl-6 {
                    padding-right: 15px;
                }
                .col-xl-3 {
                    + .col-xl-3 {
                        padding-left: 15px;
                    }
                }
            }
            .email-app-sidebar {
                .main-menu {
                    & > li {
                        text-align: right;
                        a {
                            i {
                                margin-right: unset;
                                margin-left: 10px;
                            }
                        }
                    }
                }
                ul {
                    padding-right: 0;
                }
            }
            .email-content {
                .email-top {
                    .user-emailid {
                        &:after {
                            float: left;
                        }
                    }
                }
            }
            .email-right-aside {
                .email-body {
                    .pr-0 {
                        padding-right: unset !important;
                    }
                }
                .radius-left {
                    border-top-right-radius: 0 !important;
                    border-bottom-right-radius: 0 !important;
                    border-radius: 4px;
                }
            }
        }
        .chat-menu-icons {
            text-align: left;
        }
        .chat-box {
            .chat-history {
                .row {
                    .col-sm-7 {
                        padding-left: 0 !important;
                        padding-right: unset !important;
                    }
                }
            }
        }
        .chat-menu {
            left: 0;
            right: unset;
        }
    }
    @media only screen and (max-width: 991px) {
        .faq-accordion {
            .faq-header {
                svg {
                    left: 20px;
                }
            }
        }
        .categories {
            .learning-header {
                padding-right: 20px;
            }
        }
        .page-wrapper {
            .page-main-header {
                .main-header-left {
                    .logo-wrapper {
                        img {
                            margin-left: 15px;
                            margin-right: unset;
                        }
                    }
                }
            }
            .page-body-wrapper {
                footer {
                    margin-right: 0;
                }
            }
        }
        .main {
            .langChoice {
                left: 20px;
            }
        }
        .cd-timeline-img {
            i {
                right: 50% !important;
            }
        }
        .page-wrapper {
            .page-body-wrapper {
                .page-header {
                    .breadcrumb {
                        float: right;
                        margin-right: 0;
                    }
                }
                .page-body {
                    min-height: calc(100vh - 60px);
                }
                .page-sidebar ~ .page-body {
                    margin-right: 0px !important;
                }
            }
            .page-main-header {
                margin-right: 0;
            }
        }
        .card-body {
            .value-left {
                margin-left: 20px;
            }
        }
        .page-wrapper.compact-wrapper {
            .page-main-header {
                margin-right: 0 !important;
                width: calc(100% - 0px);
            }
            .page-body-wrapper.sidebar-icon {
                .page-sidebar {
                    ~ .page-body {
                        margin-right: 0 !important;
                    }
                }
            }
        }
        .page-wrapper.compact-page {
            .page-main-header {
                margin-right: 0;
                width: calc(100% - 0px);
            }
            .page-body-wrapper.sidebar-hover {
                .page-body,
                .footer {
                    margin-right: 0 !important;
                }
            }
        }
    }
    @media (min-width: 768px) {
        .float-md-right {
            float: left !important;
        }
        .text-md-right {
            text-align: left !important;
        }
        .offset-md-3 {
            margin-right: 25%;
            margin-left: unset;
        }
        .text-md-left {
            text-align: right !important;
        }
        .ecommerce-widget {
            .text-md-right {
                text-align: left !important;
                ul {
                    text-align: left;
                }
            }
        }
    }
    @media only screen and (max-width: 767px) {
        .user-profile {
            .hovercard {
                .user-image {
                    .share-icons {
                        left: 25px !important;
                    }
                }
            }
        }
        .ecommerce-widget {
            .text-md-right {
                text-align: left !important;
            }
        }
        .bar-chart-widget {
            .earning-details {
                i {
                    left: -147px !important;
                }
            }
        }
    }
    @media (min-width: 576px) {
        .offset-sm-3 {
            margin-right: 25%;
            margin-left: unset;
        }
        .text-sm-right {
            text-align: left !important;
        }
        .float-sm-right {
            float: left !important;
        }
        .mr-sm-2,
        .mx-sm-2 {
            margin-left: 0.5rem !important;
            margin-right: unset !important;
        }
    }
    @media only screen and (max-width: 575px) {
        .faq-accordion {
            .faq-header {
                svg {
                    left: 15px;
                }
            }
        }
        .categories {
            .learning-header {
                padding-right: 15px;
            }
        }
        .user-profile {
            .hovercard {
                .user-image {
                    .share-icons {
                        left: 15px !important;
                        li {
                            margin-left: 0px;
                        }
                    }
                }
            }
        }
        .main {
            .langChoice {
                left: 15px;
            }
        }
        .page-wrapper {
            .search-form {
                .form-group {
                    margin-left: 0;
                }
            }
        }
        .card-body {
            .value-left {
                margin-left: 15px;
            }
        }
    }
    @media only screen and (max-width: 360px) {
        .social-app-profile {
            .hovercard {
                .user-image {
                    .avatar {
                        img {
                            width: 85px;
                            height: 85px;
                        }
                        margin-top: -47px;
                    }
                }
            }
        }
        .social-chat {
            .other-msg {
                margin-right: 15px;
            }
        }
    }
}

/**=====================
    60. RTL CSS Ends
==========================**/
