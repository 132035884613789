.CustomFormularioConsultaContenedores{
    margin-top: 12%;
    margin-left: 5%;
    width: 90%;
    background-color: white;
    border-radius: 10px 10px 10px 10px !important;
    padding: 2%;
    .TittleConsulta{
        font-size: 2em;
        color:#fc641c;
        font-weight: bold;
        text-align: center;
    }
    .MessageConsulta{
        margin-top: 5%;
        font-size: 2em;
        color:#fc641c;
        font-weight: bold;
        text-align: center;
    }
    .AllInputs{
        align-items: flex-end;
        .marCol{
            margin-top: 3%;
        }
        .textForm{
            font-size: 1.5em;
        }
        .textodataPicker{
            text-align: center;
            width: 100%;
            height: 35px;
        }
        .textoCont{
            text-align: center;
        }
        .save{
            width: 100%;
            background-color: #084464 !important;
            
        }
    }
    .TablaConstulta{
        margin-top: 5%;
    }
    .nota{
        font-weight: bold;
        font-size: 1.3em;
        color:#084464;
    }
}