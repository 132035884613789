.pincipal-div-input-dashbord {
    border-color: black;
    box-shadow: 5px;
    margin-top: 5%;
    margin-left: 5%;
}
.GraficaBarras {
    margin: 10%;
    .tituloGraficaBarras {
        text-align: left;
        margin-left: -5%;
    }
    .textRotation {
        transform: rotate(270deg);
    }
}
.cssentreComponentes {
    margin-top: 2%;
    margin-left: 5%;
    width: 90%;
}
.lastBar{
    margin-top: 2%;
    margin-left: 5%;
    width: 90%;
}
.CustomFormularioDashboard {
    margin-top: 10%;
    background-color: white;
    border-radius: 10px 10px 10px 10px !important;
    padding: 2%;
    .AllInputs {
        margin-top: 2%;
    }
    .RowButtonSave {
        margin-top: 2%;
        .save {
            width: 100%;
            background-color: #084464 !important;
        }
    }
    .textForm {
        font-size: 1.5em;
    }
    .tamSelect {
        width: 90%;
    }
    .textodataPicker {
        text-align: center;
        width: 70%;
    }
}
.CustomCssBar {
    box-shadow: 8px 12px #e5e5e5;
    border-radius: 15px 15px 15px 15px !important;
    margin-top: 2%;
    margin-bottom: 10%;
}

.contPadreCards {
    display: flex !important;
    flex-direction: row !important;
    flex-wrap: wrap !important;
    padding: 5%;
    .CustomCards {
        border-width: 1px;
        border-style: solid;
        border-color: gray;
        border-radius: 40px 40px 40px 40px !important;
        box-shadow: 6px 8px #9e9e9e;
        margin-top: 1%;
        margin-right: 2%;
        max-height: 150px;
        min-width: 240px;
        max-width: 240px;
        .EditFontTittle {
            text-align: center;
            font-size: 15px;
            font-weight: bold;
        }
        .EditFontCant {
            text-align: center;
            font-size: 35px;
            font-weight: bold;
        }
    }
}
#padingTemp {
    padding-left: 15%;
}
