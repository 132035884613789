.tableIap {
    .react-bootstrap-table {
        table {
            table-layout: none;
        }
    }
    .react-bootstrap-table table {
        table-layout: none;
    }
}

.imgCursor {
    cursor: pointer;
}

.remove-border-top {
    margin-top: -60px;
}

.cursor {
    cursor: pointer;
}

.height-logo {
    height: 50px;
    width: 50px;
}

.text-apm {
    color: #ff6319;
}

.mg-max {
    margin-right: 15px;
}

.w-30 {
    width: 30px;
}

.w-40 {
    width: 40px;
}

.w-44 {
    width: 46px;
}

.w-45 {
    width: 55px;
}

.overflow-auto {
    overflow: auto;
}

.w-logo {
    width: 190px;
}

.MuiDataGrid-columnHeader {
    font-weight: 700;
}

.mb-8 {
    margin-bottom: 130px;
}

.carrouselWidth {
    width: 100%;
}

.v-a-center {
    align-self: center;
    margin-top: 9%;
}

div.MuiDataGrid-colCellTitle {
    overflow: hidden;
    font-weight: 700 !important;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.mb-100 {
    margin-bottom: 100%;
}

.target-boletin {
    overflow: hidden;
    height: 300px;
}

div.MuiDataGrid-columnHeaderTitle {
    font-weight: 700 !important;
}

.mb-0 {
    margin-bottom: 0px !important;
}

.pc-center {
    place-content: center !important;
}

.carousel-control-next-icon {
    filter: invert(1) !important;
    height: 65px !important;
    width: 41px !important;
}

.carousel-control-next {
    right: -6%;
}

.carousel-control-prev-icon {
    filter: invert(1) !important;
    height: 65px !important;
    width: 41px !important;
}

.carousel-control-prev {
    left: -6%;
}

.carousel-indicators {
    li {
        filter: invert(1) !important;
    }
}

.carousel-indicators {
    .active {
        background-color: #ff6319;
    }
}

.carousel-indicators {
    bottom: -5%;
}

.mt-breadcrumb {
    margin-top: 95px;
}
